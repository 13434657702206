///////////////////////////////////////////////////////////////////////////////////////////////////
// マイプロフィール編集の趣味の選択個数は３個までとする
///////////////////////////////////////////////////////////////////////////////////////////////////
$(function(){
    $('input:checkbox').change(function() {
        let cnt = $('#hobby input:checkbox:checked').length;
        if(cnt > 3) {
            alert("４つ目は選択できません。");
            $(this).removeAttr('checked').prop('checked', false).change();
        }
    });
});

///////////////////////////////////////////////////////////////////////////////////////////////////
// マイプロフィール編集の写真
///////////////////////////////////////////////////////////////////////////////////////////////////

var isDropVisible = false;
//IEはtype="file"に標準でドラッグアンドドロップが出来ない対応。
var	ua = navigator.userAgent;
var isIe = false;
//IEかどうかの判定
$(function() {
    if (ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/7.0') > -1　|| ua.indexOf('Edge') > -1　|| ua.indexOf('edge') > -1) {
        isIe = true;
        if (window.is_chage_text) {
            $('div.img div p').empty();
            $('div.img div p').append('写真を選択<br>してください');
        }
    }
});

$(function(){
    $('div.img input[name="file"]').click(function(){
        $(this).parent('p').find('input[type="file"]').click();
    });

    //画像表示
    $('div.img input[type="file"]').change(function(e){

        var fileList = e.target.files;
        var file = null;
        var obj = $(this);
        if(fileList.length > 0){
            var file = fileList[0];
        }

        var fr = new FileReader();
        fr.onload=function(){
            obj.parents('.img').find('div.image p').empty();
            obj.parents('.img').find('div.image p').append('<img />');
            obj.parents('.img').find('div.image p').find('img').attr('src',this.result);
            obj.parents('.img').find('div.image p').find('img').attr('width','400');
            obj.parents('.img').find('.fileName').text(file.name);
        };
        fr.readAsDataURL(file);

        // 画像削除フラグ用チェックボックスを設定
        $(this).parent('p').children('input[type="checkbox"]').prop("checked",false);
    });
});

//画像削除
$(function(){
    $('div.img input[name="delete"]').click(function() {
        $(this).parent('p').parent('div').children('div').children('div.image').children('p').empty();
        if (isIe) {
            $(this).parent('p').parent('div').children('div').children('div.image').children('p').append('写真を選択<br>してください');
        } else {
            $(this).parent('p').parent('div').children('div').children('div.image').children('p').append('写真を選択<br>または<br>ドラッグ&ドロップ<br>してください');
        }
        $(this).parent('p').children('input[type="checkbox"]').prop("checked",true);
    });

});

//画像divへファイルをドラッグのイベント
function dragover(event) {
    if (isIe) {
        return false;
    }
    event.preventDefault();
    event.stopPropagation();
    if (isDropVisible == false) {
        $('article.admin form dl dd .img p.drop').animate({
            opacity: 1
        }, 300);
        isDropVisible = true;
    }
}

function dragleave(event) {
    if (isIe) {
        return false;
    }
    event.preventDefault();
    event.stopPropagation();
    if (isDropVisible == true) {
        $('article.admin form dl dd .img p.drop').animate({
            opacity: 0
        }, 300);
        isDropVisible = false;
    }
}

function dragdrop(event) {
    if (isIe) {
        return false;
    }
    setTimeout(function () {
        $('article.admin form dl dd .img p.drop').animate({opacity: 0}, 200);
        isDropVisible = false;
    }, 250);
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// マイプロフィール編集の居住地選択ボックスのoption表示・非表示の切替
///////////////////////////////////////////////////////////////////////////////////////////////////
function okayama_hide() {
    if ($('select[name="customer[residence]"] option[value="1"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="1"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="2"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="2"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="3"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="3"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="4"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="4"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="5"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="5"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="6"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="6"]').wrap('<span class="hide">');
}

function okayama_show() {
    if ($('select[name="customer[residence]"] option[value="1"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="1"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="2"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="2"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="3"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="3"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="4"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="4"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="5"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="5"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="6"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="6"]').unwrap();
}

function hiroshima_hide() {
    if ($('select[name="customer[residence]"] option[value="7"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="7"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="8"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="8"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="9"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="9"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="10"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="10"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="11"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="11"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="12"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="12"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="13"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="13"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="14"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="14"]').wrap('<span class="hide">');
}

function hiroshima_show() {
    if ($('select[name="customer[residence]"] option[value="7"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="7"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="8"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="8"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="9"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="9"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="10"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="10"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="11"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="11"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="12"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="12"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="13"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="13"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="14"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="14"]').unwrap();
}

function yamaguchi_hide() {
    if ($('select[name="customer[residence]"] option[value="15"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="15"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="16"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="16"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="17"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="17"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="18"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="18"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="19"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="19"]').wrap('<span class="hide">');
    if ($('select[name="customer[residence]"] option[value="20"]').parents().attr("class") != "hide") $('select[name="customer[residence]"] option[value="20"]').wrap('<span class="hide">');
}

function yamaguchi_show() {
    if ($('select[name="customer[residence]"] option[value="15"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="15"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="16"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="16"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="17"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="17"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="18"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="18"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="19"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="19"]').unwrap();
    if ($('select[name="customer[residence]"] option[value="20"]').parents().attr("class") == "hide") $('select[name="customer[residence]"] option[value="20"]').unwrap();
}

function residence() {
    switch($('#customer_prefecture').val()){
        case "33" :
            $('#customer_residence').show();
            okayama_show();
            hiroshima_hide();
            yamaguchi_hide();
            break;
        case "34" :
            $('#customer_residence').show();
            okayama_hide();
            hiroshima_show();
            yamaguchi_hide();
            break;
        case "35" :
            $('#customer_residence').show();
            okayama_hide();
            hiroshima_hide();
            yamaguchi_show();
            break;
        default :
            $('#customer_residence').val("");
            $('#customer_residence').hide();
    }
}
$(function(){
    residence();

    $('#customer_prefecture').change(function() {
        residence();
    });

});
///////////////////////////////////////////////////////////////////////////////////////////////////
// マイプロフィール編集　テキストエリアの文字数カウント
///////////////////////////////////////////////////////////////////////////////////////////////////
$(function () {
    $('.chara_count').bind('keydown keyup keypress change', function () {
        var thisValueLength = $(this).val().length;
        $(this).parent('dd').find('.c_count').html(thisValueLength);
    });
});
